import React, { useState } from 'react'
import { Button, Spin } from 'antd'
import { IConsumerProfileData } from '../../product.interface'
import '../analysis/Analysis.scss'
import {
  formatYearMonth,
  normalizeMarkdown
} from '../../../../helpers/helperFunctions'
import { ReactComponent as AILoader } from '../../../../icons/ai-work.svg'
import { ReactComponent as AnalysisIcon } from '../../../../icons/analysis.svg'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

interface ConsumerProfileProps {
  handleGenerateAnalysisData: (() => void) | undefined
  reportDate?: { seconds: number; nanoseconds: number }
  consumerProfileData?: IConsumerProfileData
  isLoadingData?: boolean
  reportStatus?: string
  reportMonth?: string
  isMiniLoader?: boolean
}

interface IDataBlockWithSortOrder extends IConsumerProfileData {
  sort_order?: number
}

const ConsumerProfile = React.memo(
  ({
    handleGenerateAnalysisData,
    isMiniLoader,
    consumerProfileData,
    isLoadingData,
    reportStatus,
    reportMonth,
    reportDate
  }: ConsumerProfileProps) => {
    const itemsOrder = ['likes', 'dislikes', 'personas', 'pricing', 'values']

    const sortedConsumerProfileData = consumerProfileData
      ? Object.values(consumerProfileData)
          .filter(
            (data: IDataBlockWithSortOrder | null) =>
              data && data.type && itemsOrder.includes(data.type)
          )
          .sort(
            (a, b) => itemsOrder.indexOf(a!.type) - itemsOrder.indexOf(b!.type)
          )
      : []

    return (
      <div className="mt-8">
        {isLoadingData ? (
          <div className="flex w-full justify-center items-center h-40">
            <Spin size="large" />
          </div>
        ) : (
          <div className="bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
            {reportStatus === 'pending' && (
              <div className="grid min-h-[300px] place-items-center">
                <p className="text-center font-semibold text-lg">
                  Please wait while our AI analysis is being prepared. <br />
                  You can come back to this page later.
                </p>
                <AILoader width="200px" height="200px" />
              </div>
            )}

            {sortedConsumerProfileData.length > 0 &&
              reportStatus === 'completed' && (
                <div className="space-y-4">
                  {sortedConsumerProfileData.map((data) => (
                    <div key={data.type}>
                      <ExpandableBlock
                        uniqueKey={data.type}
                        title={data.title}
                        markdownText={data.full_text}
                      />
                    </div>
                  ))}
                </div>
              )}

            {reportDate &&
              reportStatus !== 'pending' &&
              reportMonth !== formatYearMonth(reportDate) && (
                <div className="flex flex-col sm:flex-row px-8">
                  <div className="p-3 w-6/12 max-sm:w-full text-center">
                    <AnalysisIcon className="w-2/3 " />
                  </div>
                  <div className="ml-4 p-6 mb-4 w-6/12 max-sm:w-full flex items-center">
                    <div>
                      <h3 className="mb-4 font-semibold">
                        Would you like to start analyzing this product now?
                      </h3>
                      <div className="mb-4">
                        After activating this product, you will get unlimited
                        access to the Analysis & Recommendations for this month.
                      </div>
                      <Button
                        type="primary"
                        className="bg-cons-blue hover:bg-cons-blue-step-down h-10"
                        onClick={() =>
                          handleGenerateAnalysisData &&
                          handleGenerateAnalysisData()
                        }
                        loading={isMiniLoader}
                      >
                        Generate analysis
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            {sortedConsumerProfileData.length === 0 && (
              <div className="text-center m-4 font-semibold">
                No data at the moment
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
)

const ExpandableBlock = ({ title, markdownText }: any) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="rounded-lg p-4 mb-4">
      <div className="flex justify-between">
        <h2 className="font-bold text-xl mb-2 text-cons-blue">{title}</h2>
      </div>
      <div>
        <div className="relative overflow-hidden">
          <div
            className={`text-box overflow-hidden transition-all duration-300 relative ${isExpanded ? 'max-h-full' : 'max-h-60'} ${isExpanded ? '' : 'shadow-b'}`}
            style={isExpanded ? { height: 'auto' } : { maxHeight: '160px' }}
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
              {normalizeMarkdown(markdownText)}
            </ReactMarkdown>
          </div>
        </div>
        <button
          onClick={toggleExpand}
          className="text-cons-blue font-medium ml-8"
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      </div>
    </div>
  )
}

export default ConsumerProfile

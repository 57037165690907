import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  collection,
  query,
  limit,
  startAfter,
  getDocs,
  orderBy,
  Query,
  DocumentData,
  getCountFromServer,
  getFirestore,
  doc,
  getDoc,
  updateDoc
} from 'firebase/firestore'
import { message, Radio, Spin } from 'antd'

import { useParams } from 'react-router-dom'
import BackButton from '../../components/shared/backButton/BackButton'
import MainCategoryDataCard from '../../components/mainCategoryData/MainCategoryDataCard'
import Icons from '../../icons/SVGComponents/icons'
import CategoryDashboard from './categoryDetailsTabs/dashboard/CategoryDashboard'
import { BrandsData, ICategoryData, ProductIdeas } from './category.interface'
import NewProductIdeas from './categoryDetailsTabs/newProductIdeas/NewProductIdeas'
import { auth } from '../../firebase/firebase'
import axios from 'axios'
import CategoryContext from '../../contexts/CategoryProvider/CategoryProvider'
import ConsumerProfile from '../productDetails/productDetailsTabs/consumeProfile/ConsumerProfile'
import { IConsumerProfileData } from '../productDetails/product.interface'

export interface SortingParameter {
  columnKey: string
  order: 'ascend' | 'descend' | null
}

type dataTabs = 'dashboard' | 'new_ideas' | 'profile'

const CategoryDetails = () => {
  const { NewIdeasIcon, DashboardIcon, ProfileIcon } = Icons
  const pageSize = 10
  const { categoryId } = useParams()
  const db = getFirestore()
  const { categoryData, isError } = useContext(CategoryContext)!

  const [messageApi, contextHolder] = message.useMessage()
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState<dataTabs>('dashboard')
  const [productIdeasData, setProductIdeasData] = useState<
    ProductIdeas | undefined
  >(undefined)
  const [topBrandsData, setTopBrandsData] = useState<BrandsData[]>()
  const [totalTopBrands, setTotalTopBrands] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [consumerProfileData, setConsumerProfileData] =
    useState<IConsumerProfileData>()
  const [isMiniLoader, setIsMiniLoader] = useState<boolean>(false)
  const [productIdeasId, setProductIdeasId] = useState('')

  const getLastDocForPage = async (
    queryRef: Query<DocumentData>,
    offset: number
  ): Promise<DocumentData | null> => {
    const q = query(queryRef, limit(offset))
    const snapshot = await getDocs(q)
    return snapshot.docs[offset - 1] || null // Return the document at the offset
  }

  const handleTabChange = (key: dataTabs) => {
    setActiveTab(key)
    if (key === 'profile' && !consumerProfileData) {
      getProfileData()
    }
  }

  useEffect(() => {
    const getDocument = async () => {
      if (categoryId && !productIdeasId) {
        const ideasDocRef = collection(
          db,
          'categories',
          categoryId,
          'productIdeas'
        )
        const ideasSnapshot = await getDocs(ideasDocRef)
        if (!ideasSnapshot.empty) {
          const firstDoc = ideasSnapshot.docs[0]

          setProductIdeasId(firstDoc.id)
        }
      }
    }

    getDocument()
  }, [categoryData])

  const getTopBrandsData: Function = useCallback(
    async (
      pageSize: number, // Number of items per page
      currentPage: number // Current page number
    ): Promise<void> => {
      try {
        if (categoryId) {
          const topBrandsRef = collection(
            db,
            'categories',
            categoryId,
            'topBrands'
          )

          // Calculate offset
          const offset = (currentPage - 1) * pageSize

          // Base query with ordering
          let q: Query<DocumentData> = query(
            topBrandsRef,
            orderBy('consumerly_index', 'desc') // Sort by rating in descending order
          )

          // Handle pagination
          if (offset > 0) {
            const lastDocSnapshot = await getLastDocForPage(q, offset)
            if (lastDocSnapshot) {
              q = query(q, startAfter(lastDocSnapshot), limit(pageSize))
            }
          } else {
            q = query(q, limit(pageSize))
          }

          // Fetch data
          const snapshot = await getDocs(q)

          // Map data to an array
          const topBrands = snapshot.docs.map((doc) => ({
            id: doc.id, // Include document ID
            ...doc.data() // Include document fields
          }))
          //@ts-ignore
          setTopBrandsData(topBrands) // Update state with fetched data

          // Optionally, calculate the total count of documents
          const countSnapshot = await getCountFromServer(topBrandsRef)
          setTotalTopBrands(countSnapshot.data().count)
        }
      } catch (e) {
        console.error('Error fetching top brands:', e)
      } finally {
        setIsLoading(false)
      }
    },
    [categoryId]
  )

  const handleGenerateIdeas = useCallback(async (): Promise<void> => {
    try {
      setIsMiniLoader(true)
      const user = auth.currentUser
      const idToken = await user?.getIdToken()
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sendMessageToTopic`,
        {
          topic: 'topic-category-generate-analysis',
          message: {
            categoryId: categoryId
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`
          }
        }
      )
      if (response.status && categoryId) {
        const docRef = doc(db, 'categories', categoryId)

        // Update status to "pending"
        await updateDoc(docRef, {
          status: 'pending'
        })
      }
    } catch (error) {
      messageApi.open({
        type: 'error',
        content:
          'Something went wrong while generating the analysis. Please try again later.',
        onClick: () => {
          messageApi.destroy()
        }
      })
      console.error(error)
    } finally {
      setIsMiniLoader(false)
    }
  }, [])

  const getIdeasData = async (categoryId: string, ideasId: string) => {
    try {
      const db = getFirestore()
      const docRef = doc(db, 'categories', categoryId, 'productIdeas', ideasId)

      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setProductIdeasData(docSnap.data() as ProductIdeas)
      } else {
        console.error('No such document!')
        return null
      }
    } catch (error) {
      console.error('Error fetching document:', error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const getProfileData: Function = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    try {
      if (categoryId) {
        const profileDocRef = collection(
          db,
          'categories',
          categoryId,
          'consumerProfile'
        )

        // Subscribe to snapshot changes
        const profileDoc = await getDocs(profileDocRef)
        if (!profileDoc.empty) {
          //@ts-ignore
          setConsumerProfileData(profileDoc.docs[0].data())
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [categoryId])

  useEffect(() => {
    if (
      (!categoryData?.status || categoryData?.status === 'completed') &&
      productIdeasData &&
      (productIdeasData.recommendation ||
        productIdeasData.desirable_product_attributes)
    ) {
      categoryId && productIdeasId && getIdeasData(categoryId, productIdeasId)
    }
  }, [categoryData])

  useEffect(() => {
    categoryId && productIdeasId && getIdeasData(categoryId, productIdeasId)
  }, [categoryId, productIdeasId])

  const tabPanel = () => {
    return (
      <Radio.Group
        defaultValue="dashboard"
        className="flex justify-center max-sm:flex-col [&_.ant-radio-button-wrapper]:h-12 [&_.ant-radio-button-wrapper]:flex [&_.ant-radio-button-wrapper]:items-center [&_.ant-radio-button-wrapper]:justify-center [&_.ant-radio-button-wrapper]:px-5  [&_.ant-radio-button-wrapper]:bg-cons-blue/5 [&_.ant-radio-button-wrapper-checked]:bg-cons-blue [&_.ant-radio-button-wrapper-checked]:text-white ![&_.ant-radio-button-wrapper-checked]:bg-green-700"
        optionType="button"
        buttonStyle="solid"
        onChange={(event) => handleTabChange(event.target.value)}
      >
        <Radio.Button value="dashboard">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'dashboard' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-dashboard mr-3 h-6">
              <DashboardIcon
                width="22px"
                height="22px"
                stroke={`${activeTab === 'dashboard' ? '#fff' : '#2F5497'}`}
              />
            </i>
            Dashboard
          </div>
        </Radio.Button>
        <Radio.Button value="profile">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'profile' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-dashboard mr-3 h-6">
              <ProfileIcon
                width="22px"
                height="22px"
                stroke={`${activeTab === 'profile' ? '#fff' : '#2F5497'}`}
              />
            </i>
            Consumer Profile
          </div>
        </Radio.Button>
        <Radio.Button value="new_ideas">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'new_ideas' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-dashboard mr-3 h-6">
              <NewIdeasIcon
                width="22px"
                height="22px"
                stroke={`${activeTab === 'new_ideas' ? '#fff' : '#2F5497'}`}
              />
            </i>
            New Product Ideas
          </div>
        </Radio.Button>
      </Radio.Group>
    )
  }

  const getTabContent = (tab: dataTabs, data: ICategoryData) => {
    switch (tab) {
      case 'dashboard':
        return (
          <CategoryDashboard
            categoryData={data}
            topBrandsData={topBrandsData}
            isLoading={isLoading}
            handleChangePage={(page: number) => {
              getTopBrandsData(pageSize, page)
              setCurrentPage(page)
            }}
            totalTopBrands={totalTopBrands}
            currentPage={currentPage}
          />
        )
      case 'new_ideas':
        return (
          <NewProductIdeas
            productIdeasData={productIdeasData}
            isMiniLoader={isMiniLoader}
            handleGenerateIdeas={() => handleGenerateIdeas()}
            reportStatus={categoryData?.status}
            reportMonth={categoryData?.report_month}
            reportDate={categoryData?.report_date}
          />
        )
      case 'profile':
        return (
          <ConsumerProfile
            handleGenerateAnalysisData={() => handleGenerateIdeas()}
            isMiniLoader={isMiniLoader}
            isLoadingData={isLoading}
            consumerProfileData={consumerProfileData}
            reportStatus={categoryData?.status}
            reportMonth={categoryData?.report_month}
            reportDate={categoryData?.report_date}
          />
        )
      default:
        return ''
    }
  }

  return (
    <div className="max-w-6xl w-4/5 mx-auto mb-12 relative">
      <div>
        <BackButton />
        <div className="my-8 pt-1.5 text-xl">Category Insights</div>
      </div>
      {categoryData ? (
        <div>
          <MainCategoryDataCard cardData={categoryData} />
          <div>
            <div className="mt-6">{tabPanel()}</div>
            {/* @ts-ignore */}
            {categoryData && getTabContent(activeTab, categoryData)}
          </div>
        </div>
      ) : isError ? (
        <div className="font-semibold text-center text-[larger] mt-[60px]">
          Category Not Fount
        </div>
      ) : (
        <Spin className="w-full flex justify-center pt-[50px]" />
      )}
      {contextHolder}
    </div>
  )
}

export default CategoryDetails

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  getFirestore,
  query,
  collection,
  where,
  getDocs
} from 'firebase/firestore'
import { db } from '../../firebase/firebase'
import Loader from '../../components/shared/Loader/Loader'

export interface IUserProvider {
  userData: IUserContextData | null
  setUserData: Dispatch<SetStateAction<IUserContextData | null>>
  logout: () => void
}
export interface IUserData {
  id: string
  user_id?: string
  name?: string
  brand_id?: string
}

export interface ICompanyData {
  user_ids?: string[]
  brand_ids?: string[]
  category_ids?: string[]
  name?: string
}

export type IUserContextData = ICompanyData & {
  name?: string
  user_id?: string
  brand_id?: string
}

const defaultUserContext: IUserProvider = {
  userData: null,
  setUserData: () => {},
  logout: () => {}
}

// Create the context
const UserContext = createContext<IUserProvider>(defaultUserContext)

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext)

// Provider component
export const UserProvider = ({ children }: any) => {
  const auth = getAuth()
  const [userData, setUserData] = useState<IUserContextData | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const fetchCompanyData = async (user: IUserData) => {
    try {
      const q = query(
        collection(db, 'companies'),
        where('user_ids', 'array-contains', user.id)
      )

      const querySnapshot = await getDocs(q)

      if (!querySnapshot.empty) {
        const companyDoc = querySnapshot.docs[0]
        const companyData: ICompanyData = {
          // id: companyDoc.id,
          ...companyDoc.data()
        }

        setUserData({
          ...companyData,
          name: user.name,
          user_id: user.user_id,
          brand_id:
            companyData.brand_ids?.length === 1 ? companyData.brand_ids[0] : ''
        })
      } else {
        console.error('No company found for this user.')
        setUserData({
          ...user
        })
      }
    } catch (error) {
      console.error('Error fetching company for user:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const firestore = getFirestore()

    onAuthStateChanged(auth, async () => {
      const user = auth.currentUser
      if (user) {
        setIsLoading(true)
        try {
          const q = query(
            collection(firestore, 'users'),
            where('user_id', '==', user.uid)
          )
          const querySnapshot = await getDocs(q)
          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0]

            const userData: IUserData = { id: doc.id, ...doc.data() }
            if (userData) {
              await fetchCompanyData(userData)
            }
          } else {
            console.error('No matching documents.')
          }
        } catch (error) {
          console.error('Error retrieving user information:', error)
        }
      }
    })
  }, [auth])

  const logout = () => {
    setUserData(null)
  }

  return (
    <UserContext.Provider value={{ userData, setUserData, logout }}>
      {isLoading ? <Loader size="large" /> : children}
    </UserContext.Provider>
  )
}

import React from 'react'
import Chart from '../../../../components/Chart/Chart'
import { BrandsData, ICategoryData } from '../../category.interface'
import BrandEquity from '../../../../components/brandEquity/BrandEquity'
import CategoryCards from '../../../../components/customerSentiment/CustomerSentiment'
import { useParams } from 'react-router-dom'

interface CategoryDashboardProps {
  categoryData?: ICategoryData
  topBrandsData?: BrandsData[]
  isLoading: boolean
  handleChangePage: (page: number) => void
  totalTopBrands: number
  currentPage: number
}

const CategoryDashboard: React.FC<CategoryDashboardProps> = React.memo(
  ({ categoryData }: CategoryDashboardProps) => {
    const { categoryId } = useParams()

    return (
      <div>
        {categoryData?.review_trend_chart && (
          <div className="mt-6">
            <div className=" text-lg">Review Trends</div>
            <div className="text-sm mb-4 text-cons-gray">
              Category review trends for trailing 12 months with trend lines for{' '}
              <span className="text-cons-green">positive</span>,{' '}
              <span className="text-cons-yellow">neutral</span>, and{' '}
              <span className="text-cons-red">negative</span> reviews
            </div>
            <Chart dataChart={categoryData.review_trend_chart} />
          </div>
        )}
        <h2 className="mt-4 mb-2">Top Brands by Consumerly Index</h2>
        {/*Hidden for now*/}
        {/*<DataTable*/}
        {/*  loading={isLoading || !topBrandsData}*/}
        {/*  // @ts-ignore*/}
        {/*  columns={getBrandListConfig()}*/}
        {/*  dataSource={topBrandsData}*/}
        {/*  rowKey="key"*/}
        {/*  pagination={{*/}
        {/*    current: currentPage,*/}
        {/*    pageSize: 10,*/}
        {/*    showSizeChanger: false,*/}
        {/*    total: totalTopBrands,*/}
        {/*    onChange: (page: number) => handleChangePage(page)*/}
        {/*  }}*/}
        {/*/>*/}

        {categoryData?.stats?.quality_review && (
          <div className="mt-6">
            <div className="mb-2 text-lg">Review Quality</div>
            <BrandEquity
              stats={categoryData?.stats?.quality_review}
              titles={['Total Reviews', 'Verified Reviews', 'Net Reviews']}
            />
          </div>
        )}
        {categoryData?.stats?.sentiment_review && (
          <div className="mt-6">
            <div className="mb-2 text-lg">Review Sentiment</div>
            <BrandEquity
              stats={categoryData?.stats.sentiment_review}
              titles={[
                'Total Positive Reviews',
                'Total Neutral Reviews',
                'Total Negative Reviews'
              ]}
            />
          </div>
        )}
        {categoryData?.stats?.customer_sentiment && (
          <div className="mt-6">
            <div className="mb-2 text-lg">Customer Sentiment</div>
            <CategoryCards
              cardsData={categoryData.stats.customer_sentiment}
              redirectUrl={`/category-details/${categoryId}/overview/`}
            />
          </div>
        )}
      </div>
    )
  }
)

export default CategoryDashboard

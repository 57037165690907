import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  getFirestore,
  doc,
  onSnapshot,
  getDocs,
  getDoc,
  collection
} from 'firebase/firestore'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import { db } from '../../firebase/firebase'
import ProductContext, {
  ProductContextValue
} from '../../contexts/ProductProvider/ProductProvider'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

interface CategoryComparisonProps {
  categoryId: string
  brandId: string
  productId: string
  customerSentimentId: string
}
interface IProductComparisonData {
  best_product_comparison: string
  positive_negative_standout: string
  improvement_to_top: string
}

const CategoryComparison = ({
  categoryId,
  brandId,
  productId,
  customerSentimentId
}: CategoryComparisonProps) => {
  const location = useLocation()
  const { state } = location
  const { userData } = useUser()

  // @ts-ignore
  const productData: ProductContextValue | null = useContext(ProductContext)

  const [isCategoryVerified, setIsCategoryVerified] = useState(false)
  const [isBestProductComparisonExpanded, setIsBestProductComparisonExpanded] =
    useState<boolean>(false)
  const [isImprovementToTopExpanded, setIsImprovementToTopExpanded] =
    useState<boolean>(false)
  const [
    isPositiveNegativeStandoutExpanded,
    setIsPositiveNegativeStandoutExpanded
  ] = useState<boolean>(false)

  const [productComparisonData, setProductComparisonData] =
    useState<IProductComparisonData | null>(null)
  const [isDataEmpty, setIsDataEmpty] = useState(false)

  useEffect(() => {
    if (
      !productId ||
      !customerSentimentId ||
      !brandId ||
      !categoryId ||
      !userData?.category_ids?.includes(state.category_id)
    ) {
      return
    }

    const db = getFirestore()

    const docRef = collection(
      db,
      'brands',
      brandId,
      'products',
      productId,
      'customerSentiments',
      customerSentimentId,
      'productComparison'
    )

    const checkDocumentExists = async () => {
      try {
        const querySnapshot = await getDocs(docRef)

        if (!querySnapshot.empty) {
          const document = querySnapshot.docs[0]

          const docRef2 = doc(
            db,
            'brands',
            brandId,
            'products',
            productId,
            'customerSentiments',
            customerSentimentId,
            'productComparison',
            document.id
          )
          // Subscribe to document if it exists
          onSnapshot(docRef2, (docSnap) => {
            if (!querySnapshot.empty) {
              const updatedData = docSnap.data() as IProductComparisonData
              setProductComparisonData(updatedData)
              setIsDataEmpty(false)
            }
          })
        } else {
          setIsDataEmpty(true)
        }
      } catch (error) {
        console.error('Error checking document:', error)
      }
    }

    checkDocumentExists()
  }, [productId, customerSentimentId, brandId, categoryId, productData?.status])

  useEffect(() => {
    const checkCategoryExists = async () => {
      if (!categoryId) {
        console.warn('Category ID is not available in context.')
        return
      }

      try {
        const categoryRef = doc(db, 'categories', categoryId)
        const categorySnap = await getDoc(categoryRef)
        if (categorySnap.exists()) {
          setIsCategoryVerified(true) // Document exists
        }
      } catch (error) {
        console.error(error)
      }
    }

    checkCategoryExists()
  }, [state.category_id])

  return (
    <div>
      {!isDataEmpty &&
        productComparisonData &&
        (productComparisonData.best_product_comparison?.length ||
          productComparisonData.positive_negative_standout?.length ||
          productComparisonData.improvement_to_top?.length) && (
          <div>
            <h2>Product Comparison</h2>
            <div className="mt-4 bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
              <div>
                {productComparisonData &&
                  productData?.status === 'completed' && (
                    <div className="space-y-4">
                      {productComparisonData.best_product_comparison && (
                        <div className="rounded-lg p-4 mb-4">
                          <div className="flex justify-between">
                            <h2 className="font-bold text-xl mb-2 text-cons-blue">
                              How does it compare to the best product in the
                              category?
                            </h2>
                          </div>

                          <div>
                            <div className="relative overflow-hidden">
                              <div
                                className={`text-box overflow-hidden transition-all duration-300 relative ${isBestProductComparisonExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                                style={
                                  isBestProductComparisonExpanded
                                    ? { height: 'auto' }
                                    : { maxHeight: '160px' }
                                }
                              >
                                <ReactMarkdown
                                  remarkPlugins={[remarkGfm]}
                                  rehypePlugins={[rehypeRaw]}
                                >
                                  {
                                    productComparisonData.best_product_comparison
                                  }
                                </ReactMarkdown>
                              </div>
                            </div>
                            <button
                              onClick={() =>
                                setIsBestProductComparisonExpanded(
                                  !isBestProductComparisonExpanded
                                )
                              }
                              className="text-cons-blue font-medium ml-8"
                            >
                              {isBestProductComparisonExpanded
                                ? 'Read Less'
                                : 'Read More'}
                            </button>
                          </div>
                        </div>
                      )}
                      {productComparisonData.positive_negative_standout && (
                        <div className="rounded-lg p-4 mb-4">
                          <div className="flex justify-between">
                            <h2 className="font-bold text-xl mb-2 text-cons-blue">
                              What does make it positively/negatively stand out
                              form the category?
                            </h2>
                          </div>

                          <div>
                            <div className="relative overflow-hidden">
                              <div
                                className={`text-box overflow-hidden transition-all duration-300 relative ${isPositiveNegativeStandoutExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                                style={
                                  isPositiveNegativeStandoutExpanded
                                    ? { height: 'auto' }
                                    : { maxHeight: '160px' }
                                }
                              >
                                <ReactMarkdown
                                  remarkPlugins={[remarkGfm]}
                                  rehypePlugins={[rehypeRaw]}
                                >
                                  {
                                    productComparisonData.positive_negative_standout
                                  }
                                </ReactMarkdown>
                              </div>
                            </div>
                            <button
                              onClick={() =>
                                setIsPositiveNegativeStandoutExpanded(
                                  !isPositiveNegativeStandoutExpanded
                                )
                              }
                              className="text-cons-blue font-medium ml-8"
                            >
                              {isPositiveNegativeStandoutExpanded
                                ? 'Read Less'
                                : 'Read More'}
                            </button>
                          </div>
                        </div>
                      )}

                      {productComparisonData.improvement_to_top && (
                        <div className="rounded-lg p-4 mb-4">
                          <div className="flex justify-between">
                            <h2 className="font-bold text-xl mb-2 text-cons-blue">
                              What needs to be improved so the product scores at
                              the top of the category.
                            </h2>
                          </div>

                          <div>
                            <div className="relative overflow-hidden">
                              <div
                                className={`text-box overflow-hidden transition-all duration-300 relative ${isImprovementToTopExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                                style={
                                  isImprovementToTopExpanded
                                    ? { height: 'auto' }
                                    : { maxHeight: '160px' }
                                }
                              >
                                <ReactMarkdown
                                  remarkPlugins={[remarkGfm]}
                                  rehypePlugins={[rehypeRaw]}
                                >
                                  {productComparisonData.improvement_to_top}
                                </ReactMarkdown>
                              </div>
                            </div>
                            <button
                              onClick={() =>
                                setIsImprovementToTopExpanded(
                                  !isImprovementToTopExpanded
                                )
                              }
                              className="text-cons-blue font-medium ml-8"
                            >
                              {isImprovementToTopExpanded
                                ? 'Read Less'
                                : 'Read More'}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                {isDataEmpty && productData?.status === 'completed' && (
                  <div className="text-center mt-4 font-semibold">No Data</div>
                )}
              </div>
            </div>
          </div>
        )}
      {(!userData?.category_ids?.includes(state.category_id) ||
        !isCategoryVerified) && (
        <div>
          <h2>Product Comparison</h2>
          <div className="mt-4 bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
            <div className="p-6">
              <p className="text-center font-semibold text-lg">
                Category analysis is not licensed for this category. Please
                contact&nbsp;
                <a
                  href="mailto:support@consumerly.ai"
                  className="text-cons-blue underline"
                >
                  support@consumerly.ai
                </a>
                &nbsp;to enable it.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CategoryComparison

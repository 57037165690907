const ProfileIcon = ({ width = '24', height = '24', stroke = '#2F5497' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="9"
      r="3"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle
      cx="12"
      cy="12"
      r="10"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M17.9696 20C17.8105 17.1085 16.9252 15 12.0004 15C7.0757 15 6.1904 17.1085 6.03125 20"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

const NewIdeasIcon = ({ width = '24', height = '24', stroke = '#2F5497' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 19.5H9.5M14.5 19.5C14.5 18.7865 14.5 18.4297 14.5381 18.193C14.6609 17.4296 14.6824 17.3815 15.1692 16.7807C15.3201 16.5945 15.8805 16.0927 17.0012 15.0892C18.5349 13.7159 19.5 11.7206 19.5 9.5C19.5 5.35786 16.1421 2 12 2C7.85786 2 4.5 5.35786 4.5 9.5C4.5 11.7206 5.4651 13.7159 6.99876 15.0892C8.11945 16.0927 8.67987 16.5945 8.83082 16.7807C9.31762 17.3815 9.3391 17.4296 9.46192 18.193C9.5 18.4297 9.5 18.7865 9.5 19.5M14.5 19.5C14.5 20.4346 14.5 20.9019 14.299 21.25C14.1674 21.478 13.978 21.6674 13.75 21.799C13.4019 22 12.9346 22 12 22C11.0654 22 10.5981 22 10.25 21.799C10.022 21.6674 9.83261 21.478 9.70096 21.25C9.5 20.9019 9.5 20.4346 9.5 19.5"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M12 17V15" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    <path
      d="M13.7324 14C13.3866 14.5978 12.7403 15 12 15C11.2597 15 10.6134 14.5978 10.2676 14"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

const DashboardIcon = ({
  width = '24',
  height = '24',
  stroke = 'white',
  strokeWidth = '2'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.16699 12C1.16699 7.91453 1.16699 5.87178 2.4362 4.60258C3.7054 3.33337 5.74815 3.33337 9.83366 3.33337H14.167C18.2525 3.33337 20.2952 3.33337 21.5645 4.60258C22.8337 5.87178 22.8337 7.91453 22.8337 12C22.8337 16.0855 22.8337 18.1283 21.5645 19.3975C20.2952 20.6667 18.2525 20.6667 14.167 20.6667H9.83366C5.74815 20.6667 3.7054 20.6667 2.4362 19.3975C1.16699 18.1283 1.16699 16.0855 1.16699 12Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="8.20866"
      cy="12"
      r="3.79167"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M8.20801 8.20837V10.5331C8.20801 11.2436 8.20801 11.5988 8.39427 11.8739C8.58053 12.149 8.91035 12.281 9.56999 12.5448L11.3677 13.2639"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.583 12H15.2497"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M19.583 8.75H14.1663"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M19.583 15.25H16.333"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
)

export default { NewIdeasIcon, DashboardIcon, ProfileIcon }
